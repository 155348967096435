<template>
  <v-simple-table dense>
    <template v-slot:default>
      <thead>
      <tr>
        <th>Origine</th>
        <th>Montant</th>
      </tr>
      </thead>
      <tbody>
      <tr v-if="!hasSubventionTravauxInSubventions">
        <td>Subvention état travaux</td>
        <td>{{dossier.subvention_etat_travaux}} €</td>
      </tr>
      <tr>
        <td>Subvention DEAL AISFT</td>
        <td>6000.00 €</td>
      </tr>
      <tr v-if="dossier.type_dossier === 'AAH' || dossier.type_dossier === 'SOLIHA'">
        <td>Subvention maîtrise d'oeuvre</td>
        <td>{{dossier.subvention_moe}} €</td>
      </tr>
      <tr v-for="subvention in subventions" :key="subvention.id">
        <td>{{subvention.origine}}</td>
        <td>{{ subvention.montant }} €</td>
        <td v-if="!readOnly">
          <EditFinancementDialog :financement="subvention" @reload="$root.$emit('reload-financements')"></EditFinancementDialog>
        </td>
         <td v-if="!readOnly">
           <v-icon @click="deleteFinancement(subvention.id)" color="red">mdi-trash-can</v-icon>
         </td>
      </tr>
      <tr>
        <td class="font-weight-bold">Total</td>
        <td>{{total}} €</td>
      </tr>
      <tr v-if="!readOnly">
        <td v-if="customOrigine">
          <v-text-field type="text" :rules="[value => value !== null, mustStartWithPretOrSubvention]" label="Origine" v-model="financementModel.origine"></v-text-field>
        </td>
        <td v-else>
          <v-select :rules="[value => value !== null]" label="Origine" :items="origines_financement" item-value="value" item-text="value" v-model="financementModel.origine"></v-select>
        </td>
        <td>
          <v-text-field :rules="[value => value !== null]" type="number" step="0.01" label="Montant" v-model="financementModel.montant"></v-text-field>
        </td>
        <td>
          <v-icon @click="addFinancement" large color="primary">mdi-plus</v-icon>
        </td>
      </tr>
      <tr v-if="contributionThreshold >= 0.95">
        <td colspan="3">
          <p class="orange--text pt-2">
            <v-icon color="orange">mdi-exclamation</v-icon> Attention, les subventions représentent plus de 95 % du financement.
          </p>
        </td>
      </tr>
      <tr v-if="!readOnly && isAdmin">
        <td colspan="3">
          <v-checkbox v-model="customOrigine" label="Entrer l'origine manuellement"></v-checkbox>
        </td>
      </tr>
      <tr v-if="apport_personnel === undefined && !readOnly">
        <td colspan="3" class="text-center">
          <v-btn @click="addApport" :disabled="loadingApport" color="primary"><v-icon>mdi-plus</v-icon> Calculer l'apport personnel</v-btn>
        </td>
      </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>
<script>
import EditFinancementDialog from "@/views/EditFinancementDialog.vue";
import {AuthMixin} from "@/mixins/AuthMixin";

export default {
  name: 'Subventions',
  components: {EditFinancementDialog},
  mixins: [AuthMixin],
  props: {
    dossier: Object,
    readOnly: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      customOrigine: false,
      financementModel: {
        dossier: this.dossier.id,
        origine: null,
        montant: null,
      },
      loadingApport: false,

    }
  },
  methods: {
    async deleteFinancement(financementId) {
      await this.$store.dispatch("financement/deleteFinancement", {id: financementId});
      let financements = await this.$store.dispatch("financement/fetchFinancementsByDossierId", this.dossier.id);
      if(financements !== undefined) {
        this.$store.commit("dossier/setFinancements", financements);
      }
      await this.$store.dispatch("annonce/annonceSuccess", "Financement supprimé.");
      this.$root.$emit("reload-financements");
    },
    async addFinancement() {
      this.financementModel.dossier = this.dossier.id;
      let financement = await this.$store.dispatch("financement/postFinancement", this.financementModel);
      if(!financement) return;
      this.financementModel = {
        dossier: this.dossier.id,
        origine: null,
        montant: null,
      }
      let financements = await this.$store.dispatch("financement/fetchFinancementsByDossierId", this.dossier.id);
      if(financements !== undefined) {
        this.$store.commit("dossier/setFinancements", financements);
      }
      this.$root.$emit("reload-financements");
      await this.$store.dispatch("annonce/annonceSuccess", "Financement ajouté.");
    },
    async addApport() {
      this.loadingApport = true;
      if(parseFloat(this.dossier.delta) < 0) {
        this.$store.dispatch("annonce/annonceWarning", "Attention, les emplois doivent être correctement définis avant d'utiliser cette fonctions.")
        this.loadingApport = false;
        return;
      }
      let financementModel = {
        dossier: this.dossier.id,
        origine: "Apport personnel",
        montant: parseFloat(this.dossier.delta || 0),
      }
      let financement = await this.$store.dispatch("financement/postFinancement", financementModel);
      if(!financement) return;
      this.loadingApport = false;
      let financements = await this.$store.dispatch("financement/fetchFinancementsByDossierId", this.dossier.id);
      if(financements !== undefined) {
        this.$store.commit("dossier/setFinancements", financements);
      }
      this.$root.$emit("reload-financements");
      await this.$store.dispatch("annonce/annonceSuccess", "Ressource ajouté.");
    },
    mustStartWithPretOrSubvention(value) {
      return (this.removeSpecialCharacters(value).indexOf("pret") !== -1 || this.removeSpecialCharacters(value).indexOf("subvention") !== -1) || 'Doit commencer par "Prêt" ou "Subvention'
    },
    removeSpecialCharacters(value) {
      return (value || "").normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()
    },
  },
  computed: {
    selected_dossier() {
      return this.$store.getters["dossier/getSelectedDossier"];
    },
    subventions() {
      if(!this.dossier.financements) return [];
      return this.dossier.financements.filter((f) => f.origine.toLowerCase().indexOf("subvention") !== -1
          || f.origine.toLowerCase().indexOf("apport personnel") !== -1
          || f.origine.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().indexOf("pret") !== -1)
    },
    subventions_no_apport() {
      if(!this.dossier.financements) return [];
      let subventions = this.dossier.financements.filter((f) => f.origine.toLowerCase().indexOf("subvention") !== -1
          || f.origine.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().indexOf("pret") !== -1)
      subventions = subventions.filter((f) => f.origine !== "Prêt Martinique Habitat");
      return subventions;
    },
    contributionThreshold() {
      let total_subventions = this.subventions_no_apport.reduce((a, b) => parseFloat(a.montant || 0) + parseFloat(b.montant || 0), 0);

      let contribution = this.dossier.financements.find(f => f.origine === "Apport personnel")?.montant || 0;
      contribution += this.dossier.financements.find(f => f.origine === "Prêt Martinique Habitat")?.montant || 0;
      contribution += this.dossier.financements.find(f => f.origine === "Subvention/Prêt CAF")?.montant || 0;
      total_subventions += parseFloat(this.dossier.subvention_etat_travaux || 0);
      total_subventions += parseFloat(6000);
      total_subventions += parseFloat(this.dossier.maitrise_oeuvre || 0);
      let threshold = (total_subventions / (parseFloat(contribution) + total_subventions));
      return threshold;
    },
    apport_personnel() {
      return this.dossier.financements.find(f => f.origine === "Apport personnel");
    },
    total_subventions() {
      let montant = 0.0;
      for(var i = 0; i < this.subventions.length; i++) {
        montant += parseFloat(this.subventions[i].montant || 0);
      }
      return montant;
    },
    total() {
      let total = this.total_subventions;
      if(!this.hasSubventionTravauxInSubventions) total += parseFloat(this.dossier.subvention_etat_travaux || 0);
      total += parseFloat(6000);
      total += parseFloat(this.dossier.subvention_moe || 0);
      return total.toFixed(2);
    },
    origines_financement() {
      return this.$store.getters["select/origines_financement"];
    },
    hasPretMqHabitat() {
      return this.dossier.honoraires.find((honoraire) => honoraire.origine === "Frais du prêt Martinique Habitat") !== undefined;
    },
    hasSubventionTravauxInSubventions() {
      let moe = this.dossier.financements.find(financement => financement.origine === "Subvention état travaux");
      return moe !== undefined;
    }
  }
}
</script>
<style>
</style>